var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',[_c('div',{staticClass:"container-fluid p-0"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"authentication-page-content p-4 d-flex align-items-center min-vh-100"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-9"},[_c('div',[_c('div',{staticClass:"text-center"},[_vm._m(1),_c('h4',{staticClass:"font-size-18 mt-4"},[_vm._v(" "+_vm._s(_vm.$t("label.updateX", [_vm.$t('label.password')]))+" ")]),_c('p',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.$t("label.updateYourX", [_vm.$t('label.password')]))+" ")])]),_c('div',{staticClass:"p-2 mt-5"},[_c('form',{staticClass:"form-horizontal",on:{"submit":function($event){$event.preventDefault();return _vm.requestUpdatePassword.apply(null, arguments)}}},[_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-lock-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"password"}},[_vm._v(_vm._s(_vm.$t("label.password")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateForm.password),expression:"updateForm.password"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted && _vm.$v.updateForm.password.$error,
                            },attrs:{"type":"password","id":"password","placeholder":_vm.$t('label.enter', [_vm.$t('label.password')])},domProps:{"value":(_vm.updateForm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.updateForm, "password", $event.target.value)}}})]),(_vm.submitted && _vm.$v.updateForm.password.$error)?_c('div',{staticStyle:{"color":"#ff3d60","font-size":"80%","margin-top":"-20px"}},[(!_vm.$v.updateForm.password.required)?_c('span',[_vm._v(_vm._s(_vm.$t("message.requiredX", [_vm.$t("label.password")])))]):_vm._e(),(!_vm.$v.updateForm.password.minlength ||
                              !_vm.$v.updateForm.password.maxLength ||
                              !_vm.$v.updateForm.password.containsSpecial ||
                              !_vm.$v.updateForm.password.containsNumber ||
                              !_vm.$v.updateForm.password.containsLowercase ||
                              !_vm.$v.updateForm.password.containsUppercase
                              )?_c('span',[_vm._v(_vm._s(_vm.$t("message.passwordConstraint")))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-group auth-form-group-custom mb-4"},[_c('i',{staticClass:"ri-lock-2-line auti-custom-input-icon"}),_c('label',{attrs:{"for":"confirmationPassword"}},[_vm._v(_vm._s(_vm.$t("label.confirmationPassword")))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.updateForm.confirmationPassword),expression:"updateForm.confirmationPassword"}],staticClass:"form-control",class:{
                              'is-invalid':
                                _vm.submitted &&
                                _vm.$v.updateForm.confirmationPassword.$error,
                            },attrs:{"type":"password","id":"confirmationPassword","placeholder":_vm.$t('label.enter', [_vm.$t('label.confirmationPassword')])},domProps:{"value":(_vm.updateForm.confirmationPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.updateForm, "confirmationPassword", $event.target.value)}}}),(_vm.submitted &&
                            _vm.$v.updateForm.confirmationPassword.$error
                            )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.updateForm.confirmationPassword.required ||
                              !_vm.$v.updateForm.confirmationPassword.sameAs
                              )?_c('span',[_vm._v(_vm._s(_vm.$t("message.requiredX", [ _vm.$t("label.confirmationPassword"),])))]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"mt-4 text-center"},[_c('button',{staticClass:"btn btn-primary w-md waves-effect waves-light",attrs:{"type":"submit","disabled":_vm.status.processiong}},[_vm._v(" "+_vm._s(_vm.$t("label.reset"))+" ")])])])]),_c('div',{staticClass:"mt-5 text-center"},[_c('p',[_vm._v("© "+_vm._s(new Date().getFullYear())+" Nazox.")])])])])])])])]),_vm._m(2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-btn d-none d-sm-block"},[_c('a',{attrs:{"href":"/"}},[_c('i',{staticClass:"mdi mdi-home-variant h2 text-white"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',{staticClass:"logo",attrs:{"href":"/"}},[_c('img',{staticStyle:{"height":"30%","width":"30%"},attrs:{"src":require("@/assets/logo_hextar_correct.png"),"alt":"logo"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-8"},[_c('div',{staticClass:"authentication-bg"},[_c('div',{staticClass:"bg-overlay"})])])
}]

export { render, staticRenderFns }